import React from 'react';

import Layout from '../components/Layout';
import {Helmet} from "react-helmet";

export const PhysicianPageTemplate = () => {
  setTimeout(() => {
    const $ = window.$;
    window.document.getElementById('phone').addEventListener('input', function () { //Using input event for instant effect
      let text = this.value                                                  //Get the value
      text = text.replace(/\D/g, '')                                         //Remove illegal characters
      if (text.length > 3) text = text.replace(/.{3}/, '$&-')                //Add hyphen at pos.4
      if (text.length > 7) text = text.replace(/.{7}/, '$&-')                //Add hyphen at pos.8
      this.value = text;
      // document ready
    });
    window.document.getElementById('fax').addEventListener('input', function () { //Using input event for instant effect
      let text = this.value                                                //Get the value
      text = text.replace(/\D/g, '')                                       //Remove illegal characters
      if (text.length > 3) text = text.replace(/.{3}/, '$&-')              //Add hyphen at pos.4
      if (text.length > 7) text = text.replace(/.{7}/, '$&-')              //Add hyphen at pos.8
      this.value = text;
      // document ready
    });
    window.document.getElementById('zip').addEventListener('input', function () {
      let text = this.value
      text = text.replace(/\D/g, '')
      this.value = text;
    });
    window.$(".nav-tabs a").click(function () {
      $(this).tab('show');
    });
    // Auto insert phone number hyphens
    $('#phone').keyup(window.autoInsertHyphens);

    $('.carousel-item', '.show-neighbors').each(function () {
      var next = $(this).next();
      if (!next.length) {
        next = $(this).siblings(':first');
      }
      next.children(':first-child').clone().appendTo($(this));
    }).each(function () {
      var prev = $(this).prev();
      if (!prev.length) {
        prev = $(this).siblings(':last');
      }
      prev.children(':nth-last-child(2)').clone().prependTo($(this));
    });

    $('.show_on_mobile .carousel').carousel({
      interval: 3000,
      cycle: true
    });

  }, 2000);

  function resetForm($form) {
    $form.find('input:text, input:password, input:file, select, textarea').val('');
    $form.find('input:radio, input:checkbox')
      .removeAttr('checked').removeAttr('selected');
  }

  let submitForm = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const $ = window.$;
    const formId = 'physicianForm';
    const responseTextId = 'response-text';
    const url = 'https://hooks.zapier.com/hooks/catch/1846901/o910mfy/';
    const Http = new XMLHttpRequest();
    const form = window.document.getElementById(formId);
    function handleStateChange() {
      if(Http.readyState === XMLHttpRequest.DONE) {
        var status = Http.status;

        if (status >= 200 && status < 400) {
          window.document.getElementById(responseTextId).innerText = 'Form successfully submitted!';
          resetForm($(form));
        } else {
          window.document.getElementById('response-text').innerText =
            'There was an error submitting the form, please try again';
        }
      }
    }
    Http.onreadystatechange = handleStateChange;

    const formData = new window.FormData(form);
    Http.open('POST', url);
    Http.send(formData);
  };
  return (
  <main role="main" className="physician-page">
    <Helmet>
      <title>Physician</title>
    </Helmet>
    <div>
      {/* Begin Banner */}
      <div className="jumbotron physician mb-0" style={{"backgroundImage": "linear-gradient(90deg, rgba(63, 62, 62, 0.3), rgba(63, 62, 62, 0.3)), url(assets/newimg/physicianbanner.png)"}}>
        <div className="container text-light">
          {/* <h1 class="display-5 text-white">Some interduction<br> text for physicians <br>here</h1>
                  <a class="btn btn-gr the-bnr-btn pl-3 pr-3 fw-6" href="#prescription-form" role="button">Submit a prescription</a> */}
        </div>
      </div>{/* End Banner */}
      {/* Begin Second section */}
      <div className="container pt-5 pb-5 ph-second-wrap text-center">
        <h2 className="py-5 mb-3">Need a reason to work with us. Here are three.</h2>
        <div className="row">
          <div className="col-md-4">
            <div className="text-center">
              <div className="acc feature-img-bg mx-auto"><img className="circle-icon" src="/assets/icons/physician/1.png" alt="" /></div>
              <h3>In Expert Company</h3>
              <p>Practice with confidence knowing you'll be part of an exclusive network of other
                physicians, many of whom are thought leaders in their field.</p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="text-center">
              <div className="acc feature-img-bg mx-auto"><img className="circle-icon" src="/assets/icons/physician/2.png" alt="" /></div>
              <h3>Better Outcomes</h3>
              <p>With motivated, ambulatory patients guided by world-class physical therapists, the
                probability of positive results for your patients ends up skyrocketing.</p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="text-center">
              <div className="acc feature-img-bg mx-auto"><img className="circle-icon" src="/assets/icons/physician/3.png" alt="" /></div>
              <h3>Driven By Technology</h3>
              <p>From billing to scheduling to updating treatment plans, PeteHealth is always finding
                the right technology to improve patient outcomes.</p>
            </div>
          </div>
        </div>
      </div>{/* End Second section */}
      {/* Begin Third section */}
      <div className="jumbotron physician-works mb-0">
        <div className="container">
          <div className="row">
            <div className="col-md-7" />
            <div className="col-md-5 rounded card bg-white p-4 md-bg-none">
              <h2 className="display-5 mb-3 md-text-white">How Pete Makes It<br />
                Easy for Physicians.</h2>
              <p className="md-text-white">Patients and therapists coordinate to schedule treatments and
                handle payments through the latest healthcare-centric technologies. Coordinated and
                streamlined, it's physical therapy that helps ensure better patient outcomes.</p>
              <div className="container md-text-white">
                <div className="row py-3">
                  <div className="col-md-3 col-sm-12 acc feature-img-bg mx-auto text-center"><img src="/assets/icons/physician/4.png" /></div>
                  <div className="col-md-9 col-sm-12 md-text-white">
                    <h3 className="pt-2 md-text-white">Book your Evaluation</h3>
                    <p>We'll help coordinate the best time for a PeteHealth therapist to evaluate
                      you.</p>
                  </div>
                </div>
                <div className="row py-3">
                  <div className="col-md-3 col-sm-12 acc feature-img-bg mx-auto text-center"><img src="/assets/icons/physician/5.png" /></div>
                  <div className="col-md-9 col-sm-12">
                    <h3 className="pt-2 md-text-white">Pay in a click</h3>
                    <p>We accept most insurance or you can pay a flat fee per visit.</p>
                  </div>
                </div>
                <div className="row py-3">
                  <div className="col-md-3 col-sm-12 acc feature-img-bg mx-auto text-center"><img src="/assets/icons/physician/6.png" /></div>
                  <div className="col-md-9 col-sm-12">
                    <h3 className="pt-2 md-text-white">Get physical</h3>
                    <p>Easy and convenient, get the care you need in your home or office.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>{/* End Third section */}
      {/* Begin Fourth Section */}
      <div className="container-fluid text-center license-the">
        <div className="container">
          <h2>Embraced by the Medical Community</h2>
          <p>Ready to treat you wherever you need us. Say hello to the faces of PeteHealth.</p>
          <div className="container">
            <div className="row pb-4">
              <div className="w-100">
                {/* Top content */}
                <div id="carouselExampleIndicators" className="ph-carousel carousel slide hide_on_mobile" data-ride="carousel">
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <div className="row">
                        <div className="col-md-3 col-sm-12">
                          <div className="team-wrap card">
                            <img src="/assets/newimg/doctor1.png" className="img-fluid mx-auto d-block" alt="img1" />
                            <div className="cont">
                              <h3>Jason Snibbe, MD</h3><small style={{marginTop: 0, paddingTop: 0}}>Board Certified Orthopedic Surgeon<br />
                              <br /></small>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 col-sm-12">
                          <div className="team-wrap card">
                            <img src="/assets/newimg/doctor2.png" className="img-fluid mx-auto d-block" alt="img2" />
                            <div className="cont">
                              <h3>Sanjay Khurana, MD</h3><small style={{marginTop: 0, paddingTop: 0}}>ACGME-approved Spine Surgeon<br />
                              <br /></small>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 col-sm-12">
                          <div className="team-wrap card">
                            <img src="/assets/newimg/doctor3.png" className="img-fluid mx-auto d-block" alt="img1" />
                            <div className="cont">
                              <h3>Michael Gerhardt, MD</h3><small style={{marginTop: 0, paddingTop: 0}}>Board Certified Orthopedic Surgeon<br />
                              <br /></small>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 col-sm-12">
                          <div className="team-wrap card">
                            <img src="/assets/newimg/doctor4.png" className="img-fluid mx-auto d-block" alt="img1" />
                            <div className="cont">
                              <h3>Michael Port, MD</h3><small style={{marginTop: 0, paddingTop: 0}}>Board Certified Anesthesiologist Pain
                              Medicine</small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <a class="carousel-control-prev ca-shadow-sm" href="#carouselExampleIndicators" role="button" data-slide="prev">
                                                                  &#x2190;
                                  </a>
                                                  <a class="carousel-control-next ca-shadow-sm" href="#carouselExampleIndicators" role="button" data-slide="next">
                                                                  &#8594;
                                  </a> */}
                </div>{/* Mobile Carousel */}
                {/* For Mobile view Slider Start */}
                <div className="bd-example show_on_mobile">
                  <div id="carouselExampleIndicatorsmob" className="carousel slide show-neighbors" data-ride="carousel">
                    <div className="carousel-inner">
                      <div className="carousel-item active">
                        <div className="item__third">
                          <div className="team-wrap card">
                            <img src="/assets/newimg/doctor1.png" className="img-fluid mx-auto d-block" alt="img1" />
                            <div className="cont">
                              <h3>Jason Snibbe, MD</h3>
                              <p>Board Certified<br />
                                Orthopedic Surgeon</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="carousel-item">
                        <div className="item__third">
                          <div className="team-wrap card">
                            <img src="/assets/newimg/doctor2.png" className="img-fluid mx-auto d-block" alt="img2" />
                            <div className="cont">
                              <h3>Sanjay Khurana, MD</h3><small style={{marginTop: 0, paddingTop: 0}}>ACGME-approved Spine Surgeon<br />
                              <br /></small>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="carousel-item">
                        <div className="item__third">
                          <div className="team-wrap card">
                            <img src="/assets/newimg/doctor3.png" className="img-fluid mx-auto d-block" alt="img1" />
                            <div className="cont">
                              <h3>Michael Gerhardt, MD</h3><small style={{marginTop: 0, paddingTop: 0}}>Board Certified Orthopedic Surgeon<br />
                              <br /></small>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="carousel-item">
                        <div className="item__third">
                          <div className="team-wrap card">
                            <img src="/assets/newimg/doctor4.png" className="img-fluid mx-auto d-block" alt="img1" />
                            <div className="cont">
                              <h3>Michael Port, MD</h3><small style={{marginTop: 0, paddingTop: 0}}>Board Certified Anesthesiologist Pain
                              Medicine</small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <ul className="carousel-indicators">
                      <li data-target="#carouselExampleIndicatorsmob" data-slide-to={0} className="active">a</li>
                    </ul><a className="carousel-control-prev ca-shadow-sm" href="#carouselExampleIndicatorsmob" role="button" data-slide="prev">←</a>
                    <a className="carousel-control-next ca-shadow-sm" href="#carouselExampleIndicatorsmob" role="button" data-slide="next">→</a>
                  </div>
                </div>{/* End */}
              </div>
            </div>
          </div>
        </div>
      </div>{/* End Fourth Section */}
      {/* Begin Fifth Section */}
      <div id="pricing_and_ins" className="bg-gray text-center" >
        <div className="medi-wrap">
          <div className="row mx-auto row-1">
            <div className="col-md-5 text-left">
              <h2> Good news. If you're a patient who qualifies, PeteHealth is fully covered by Medicare.
                <p />
                <img src="/assets/newimg/medicaretrans.png" style={{width: '200px', margin: 'auto'}} />
              </h2></div>
            <div className="col-md-7 text-left phy-med-7">
              <div className="comm-ins">
                <div className="comm-inner">
                  <h2> We'll take your insurance </h2>
                  <p>If you have health insurance, chances are you'll be covered. Here are
                    a few of the insurance companies that we accept.</p>
                  <p className="com-images-wrap"><span><img className="img-fluid" src="/assets/img/ph-cm-1.png" alt="" /></span><span><img src="/assets/img/ph-cm-2.png" alt="" /></span><span><img src="/assets/img/ph-cm-3.png" alt="" /></span></p>
                  <p className="com-images-wrap"><span><img src="/assets/img/ph-cm-4.png" alt="" /></span><span><img src="/assets/img/ph-cm-6.png" alt="" /></span></p>
                  <br />
                  <h2>Or pay just a flat fee of $175.</h2>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>{/* End Fifth Section */}
      <div className="container-fluid text-center license-the trainer-section">
        <div className="container">
          <div className="row">
            <div className="w-100">
              {/* Top content */}
              <div id="trainercarouselExampleIndicators" className="ph-carousel carousel slide hide_on_mobile" data-ride="carousel">
                <div className="carousel-inner">
                  <div id="ptcards">
                    <div>
                      <div className="carousel-item active">
                        <div className="row" style={{justifyContent: 'space-evenly'}}>
                          <div className="col-md-3 col-sm-12">
                            <button type="button" style={{border: 'none', boxShadow: 'none', background: 'none', padding: 0, margin: 0}} data-toggle="modal" data-target="#exampleModalalex">
                              <div className="team-wrap card">
                                <img src="/assets/newimg/trainer2.png" className="img-fluid mx-auto d-block" alt="img2" />
                                <div className="cont">
                                  <h3>Alex Lui, DPT</h3>
                                  <small style={{marginTop: 0, paddingTop: 0}}>
                                    Manual Therapy,
                                    Chronic Neck &amp; Low Back Pain,
                                    Post-Op Rehab
                                  </small>
                                </div>
                              </div>
                            </button>
                          </div>
                          <div className="col-md-3 col-sm-12">
                            <button type="button" style={{border: 'none', boxShadow: 'none', background: 'none', padding: 0, margin: 0}} data-toggle="modal" data-target="#exampleModalamy">
                              <div className="team-wrap card">
                                <img src="/assets/newimg/trainer4.png" className="img-fluid mx-auto d-block" alt="img2" />
                                <div className="cont">
                                  <h3>Amy Smith, DPT</h3>
                                  <small style={{marginTop: 0, paddingTop: 0}}>
                                    Manual Therapy,
                                    Spinal Rehab,
                                    Shoulder Injuries,
                                    Post-Op Rehab
                                  </small>
                                </div>
                              </div>
                            </button>
                          </div>
                          <div className="col-md-3 col-sm-12">
                            <button type="button" style={{border: 'none', boxShadow: 'none', background: 'none', padding: 0, margin: 0}} data-toggle="modal" data-target="#exampleModaldave">
                              <div className="team-wrap card">
                                <img src="/assets/newimg/trainer5.png" className="img-fluid mx-auto d-block" alt="img2" />
                                <div className="cont">
                                  <h3>Dave Halberg, PT</h3>
                                  <small style={{marginTop: 0, paddingTop: 0}}>
                                    Post-Surgical Rehab,
                                    Functional Training,
                                    Orthopedics
                                  </small>
                                </div>
                              </div>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="carousel-item">
                        <div className="row" style={{justifyContent: 'space-evenly'}}>
                          <div className="col-md-3 col-sm-12">
                            <button type="button" style={{border: 'none', boxShadow: 'none', background: 'none', padding: 0, margin: 0}} data-toggle="modal" data-target="#exampleModalcamille">
                              <div className="team-wrap card">
                                <img src="/assets/newimg/trainer3.png" className="img-fluid mx-auto d-block" alt="img2" />
                                <div className="cont">
                                  <h3>Camille Choi, DPT</h3>
                                  <small style={{marginTop: 0, paddingTop: 0}}>
                                    Cupping/Myofascial Decompression,
                                    Joint &amp; Spine Post-Op Rehab
                                  </small>
                                </div>
                              </div>
                            </button>
                          </div>
                          <div className="col-md-3 col-sm-12">
                            <button type="button" style={{border: 'none', boxShadow: 'none', background: 'none', padding: 0, margin: 0}} data-toggle="modal" data-target="#exampleModalted">
                              <div className="team-wrap card">
                                <img src="/assets/newimg/trainer1.png" className="img-fluid mx-auto d-block" alt="img2" />
                                <div className="cont">
                                  <h3>Ted Steben, MPT</h3>
                                  <small style={{marginTop: 0, paddingTop: 0}}>
                                    Post-Op Rehab,
                                    Manual Therapy,
                                    Shoulder Injuries,
                                    Knee Injuries
                                  </small>
                                </div>
                              </div>
                            </button>
                          </div>
                          <div className="col-md-3 col-sm-12">
                            <button type="button" style={{border: 'none', boxShadow: 'none', background: 'none', padding: 0, margin: 0}} data-toggle="modal" data-target="#exampleModalcindy">
                              <div className="team-wrap card">
                                <img src="/assets/newimg/trainer7.png" className="img-fluid mx-auto d-block" alt="img2" />
                                <div className="cont">
                                  <h3>Cindy Glaser, DPT</h3>
                                  <small style={{marginTop: 0, paddingTop: 0}}>
                                    Orthopedics,
                                    Spine Rehabilitation,
                                    Chronic Pain Treatment,
                                    Pilates
                                  </small>
                                </div>
                              </div>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div><a className="carousel-control-prev ca-shadow-sm" href="#trainercarouselExampleIndicators" role="button" data-slide="prev">←</a>
                <a className="carousel-control-next ca-shadow-sm" href="#trainercarouselExampleIndicators" role="button" data-slide="next">→</a>
              </div>{/* Mobile Carousel */}
              {/* For Mobile view Slider Start */}
              <div className="bd-example show_on_mobile">
                <div id="trainercarouselExampleIndicatorsmob" className="carousel slide show-neighbors" data-ride="carousel">
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <div className="item__third">
                        <div className="team-wrap card">
                          <button type="button" style={{border: 'none', boxShadow: 'none', background: 'none', padding: 0, margin: 0}} data-toggle="modal" data-target="#exampleModalalex"><img src="/assets/newimg/trainer2.png" className="img-fluid mx-auto d-block" alt="img1" /></button>
                          <div className="cont">
                            <h3><button type="button" style={{border: 'none', boxShadow: 'none', background: 'none', padding: 0, margin: 0}} data-toggle="modal" data-target="#exampleModalalex">Alex Lui,
                              DPT</button></h3>
                            <p><button type="button" style={{border: 'none', boxShadow: 'none', background: 'none', padding: 0, margin: 0}} data-toggle="modal" data-target="#exampleModalalex">Manual Therapy,
                              Chronic Pain Treatment, Orthopedics</button></p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <div className="item__third">
                        <div className="team-wrap card">
                          <button type="button" style={{border: 'none', boxShadow: 'none', background: 'none', padding: 0, margin: 0}} data-toggle="modal" data-target="#exampleModalamy"><img src="/assets/newimg/trainer4.png" className="img-fluid mx-auto d-block" alt="img2" /></button>
                          <div className="cont">
                            <h3><button type="button" style={{border: 'none', boxShadow: 'none', background: 'none', padding: 0, margin: 0}} data-toggle="modal" data-target="#exampleModalamy">Amy Smith,
                              DPT</button></h3>
                            <p><button type="button" style={{border: 'none', boxShadow: 'none', background: 'none', padding: 0, margin: 0}} data-toggle="modal" data-target="#exampleModalamy">Manual Therapy, Spinal
                              Rehab, Shoulder injuries, Post-Op Rehab</button></p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <div className="item__third">
                        <div className="team-wrap card">
                          <button type="button" style={{border: 'none', boxShadow: 'none', background: 'none', padding: 0, margin: 0}} data-toggle="modal" data-target="#exampleModaldave"><img src="/assets/newimg/trainer5.png" className="img-fluid mx-auto d-block" alt="img1" /></button>
                          <div className="cont">
                            <h3><button type="button" style={{border: 'none', boxShadow: 'none', background: 'none', padding: 0, margin: 0}} data-toggle="modal" data-target="#exampleModaldave">Dave Halberg,
                              PT</button></h3>
                            <p><button type="button" style={{border: 'none', boxShadow: 'none', background: 'none', padding: 0, margin: 0}} data-toggle="modal" data-target="#exampleModaldave">Biomechanical
                              Analysis, Post-Surgical Rehab, Functional training, Orthopedic
                              Rehab</button></p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <div className="item__third">
                        <div className="team-wrap card">
                          <button type="button" style={{border: 'none', boxShadow: 'none', background: 'none', padding: 0, margin: 0}} data-toggle="modal" data-target="#exampleModalcamille"><img src="/assets/newimg/trainer3.png" className="img-fluid mx-auto d-block" alt="img1" /></button>
                          <div className="cont">
                            <h3><button type="button" style={{border: 'none', boxShadow: 'none', background: 'none', padding: 0, margin: 0}} data-toggle="modal" data-target="#exampleModalcamille">Camille Choi,
                              DPT</button></h3>
                            <p><button type="button" style={{border: 'none', boxShadow: 'none', background: 'none', padding: 0, margin: 0}} data-toggle="modal" data-target="#exampleModalcamille">Functional
                              Exercise Rehab, Cupping/Myofascial decompression, Joint And Spine Post-op
                              Rehab</button></p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <div className="item__third">
                        <div className="team-wrap card">
                          <button type="button" style={{border: 'none', boxShadow: 'none', background: 'none', padding: 0, margin: 0}} data-toggle="modal" data-target="#exampleModalted"><img src="/assets/newimg/trainer1.png" className="img-fluid mx-auto d-block" alt="img1" /></button>
                          <div className="cont">
                            <h3 className="text-left"><button type="button" style={{border: 'none', boxShadow: 'none', background: 'none', padding: 0, margin: 0}} data-toggle="modal" data-target="#exampleModalted">Ted Steben,
                              MPT</button></h3>
                            <p className="text-left"><button type="button" style={{border: 'none', boxShadow: 'none', background: 'none', padding: 0, margin: 0}} data-toggle="modal" data-target="#exampleModalted">Post-Op Rehab, Manual
                              Therapy, Shoulder injuries, Knee injuries</button></p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <div className="item__third">
                        <div className="team-wrap card">
                          <button type="button" style={{border: 'none', boxShadow: 'none', background: 'none', padding: 0, margin: 0}} data-toggle="modal" data-target="#exampleModalcindy"><img src="/assets/newimg/trainer7.png" className="img-fluid mx-auto d-block" alt="img1" /></button>
                          <div className="cont">
                            <h3><button type="button" style={{border: 'none', boxShadow: 'none', background: 'none', padding: 0, margin: 0}} data-toggle="modal" data-target="#exampleModalcindy">Cindy Glaser,
                              DPT</button></h3>
                            <p><button type="button" style={{border: 'none', boxShadow: 'none', background: 'none', padding: 0, margin: 0}} data-toggle="modal" data-target="#exampleModalcindy">Orthopedics, Spine
                              Rehabilitation, Chronic Pain Treatment, Movement Retraining,
                              Pilates</button></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ul className="carousel-indicators">
                    <li data-target="#trainercarouselExampleIndicatorsmob" data-slide-to={0} className="active">a</li>
                  </ul><a className="carousel-control-prev ca-shadow-sm" href="#trainercarouselExampleIndicatorsmob" role="button" data-slide="prev">←</a>
                  <a className="carousel-control-next ca-shadow-sm" href="#trainercarouselExampleIndicatorsmob" role="button" data-slide="next">→</a>
                </div>
              </div>{/* End */}
            </div>
          </div>
        </div>
      </div>{/* Begin Sixth section */}
      <div className="container pt-5 pb-5 text-center conntect-pr">
        <h2>Connecting with us</h2>
        <div className="row mt-5">
          <div className="col-md-4">
            <div className="text-center">
              <div className="acc feature-img-bg mx-auto"><img src="/assets/icons/physician/7.png" alt="" /></div>
              <h3>Online</h3>
              <p>Desktop or mobile, you can send us a script for your patient.</p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="text-center">
              <div className="acc feature-img-bg mx-auto"><img src="/assets/icons/physician/8.png" alt="" /></div>
              <h3>Rx pads</h3>
              <p>Ask for a pad of scripts and you can write for us old school style.</p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="text-center">
              <div className="acc feature-img-bg mx-auto"><img src="/assets/icons/physician/9.png" alt="" /></div>
              <h3>In your EMR</h3>
              <p>Since we already are in the most EMRs just refer us in your system.</p>
            </div>
          </div>
        </div>
      </div>{/* End Sixth Section */}
      {/* Begining Seventh Section */}
      <div id="prescription-form" className="bg-gray pt-5 pb-5 text-center ph-form-wrap">
        <div className="pb-4 mt-5">
          <h2>Please complete the form below.</h2>
          <p>Provide all pertinent information in detail.</p>
        </div>
        <div id="response-text" className="text-center text-danger pb-2" />
        <div className="physician-form ca-shadow-sm rounded p-4 bg-white m-auto">
          <form className="container" id="physicianForm" name="physicianForm" onSubmit={submitForm}>
            <div className="row">
              <div className="form-group col-md-6 col-sm-12">
                <input className="form-control fa-input" id="fname" name="fname" placeholder="Patient First Name*" type="text" required />
              </div>{/* form-group */}
              <div className="form-group col-md-6 col-sm-12">
                <input className="form-control fa-input" id="lname" name="lname" placeholder="Patient Last Name*" type="text" required />
              </div>{/* form-group */}
            </div>
            <div className="row">
              <div className="form-group col-md-6 col-sm-12">
                <input className="form-control fa-input" maxLength={12} id="phone" name="phone" placeholder="Phone Number*" type="text" required />
              </div>{/* form-group */}
              <div className="form-group col-md-6 col-sm-12">
                <input className="form-control fa-input" id="zip" maxLength={5} name="zip" placeholder="Zipcode" type="text" required />
              </div>{/* form-group */}
            </div>
            <div className="row">
              <div className="form-group col-12">
                <textarea className="form-control fa-input" id="reasonforvisit" name="reasonforvisit" rows={2} placeholder="Diagnosis*" defaultValue={""} />
              </div>{/* form-group */}
            </div>
            <div className="row">
              <div className="form-group col-md-6 col-sm-12">
                <input className="form-control fa-input" id="fsession" name="fsession" placeholder="Desired date of 1st session" type="text" />
              </div>{/* form-group */}
              <div className="form-group col-md-6 col-sm-12">
                <input className="form-control fa-input" id="session" name="session" placeholder="Desired #sessions per week / # of weeks" type="text" />
              </div>{/* form-group */}
            </div>
            <div className="row">
              <div className="form-group col-12">
                <textarea className="form-control fa-input" id="rx" name="rx" rows={2} placeholder="Rx (Precautions, Prehab, Pre-op home Assessment, etc.)" defaultValue={""} />
              </div>{/* form-group */}
            </div>
            <div className="row">
              <div className="form-group col-md-6 col-sm-12">
                <input className="form-control fa-input" id="phfname" name="phfname" placeholder="Physician First Name*" type="text" required />
              </div>{/* form-group */}
              <div className="form-group col-md-6 col-sm-12">
                <input className="form-control fa-input" id="phlname" name="phlname" placeholder="Physician Last Name*" type="text" required />
              </div>{/* form-group */}
            </div>
            <div className="row">
              <div className="form-group col-md-6 col-sm-12">
                <input className="form-control fa-input" id="cname" name="cname" placeholder="Clinic Name*" type="text" required />
              </div>{/* form-group */}
              <div className="form-group col-md-6 col-sm-12">
                <input className="form-control fa-input" id="fax" maxLength={12} name="fax" placeholder="Clinic Fax Number*" type="text" required />
              </div>{/* form-group */}
            </div>
            <div className="row">
              <div className="form-group col-md-6 col-sm-12">
                <input className="form-control fa-input" id="phphone" name="phphone" placeholder="NPI Number*" type="text" required />
              </div>{/* form-group */}
              <div className="form-group col-md-6 col-sm-12">
                <input aria-describedby="emailHelp" className="form-control fa-input" id="phemail" name="phemail" placeholder="Physician Email*" type="email" required />
              </div>{/* form-group */}
            </div><button className="btn ca-btn btn-gr p-3 w-100 ph-sub" type={'submit'}>Submit</button>
            <button className="btn ca-btn btn-gr p-3 w-100 ph-sub2" type={'submit'}>Request a
              therapist</button>
          </form>
        </div>
        <div className="py-4" />
        {/* <h3 class="my-5 fax"><a href="#"> or send by Fax: 234 567 89 01</a></h3> */}
      </div>{/* End Seventh Section */}
    </div>

  </main>
  );
};

const PhysicianPage = () => {
  return (
    <Layout>
      <PhysicianPageTemplate />
    </Layout>
  )
};


export default PhysicianPage;
